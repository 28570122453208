import sizeMaker from "../sizeMaker";

export default [
  {
    title: "Тех. задание",
    status: "pen",
    author: "Жургенов Абас",
    updatedAt: "30.10.2020",
    type: "acrobat",
    size: sizeMaker(5000),
    project: "21.010.03 Газго",
    updatedBy: "Жургенов Абас",
    takenBy: "Жургенов Абас",
  },

  {
    title: "Название документа",
    status: "pen",
    author: "Жургенов Абас",
    updatedAt: "30.10.2020",
    type: "word",
    size: sizeMaker(100),
    project: "21.010.03 Газго",
    updatedBy: "Жургенов Абас",
    takenBy: "Жургенов Абас",
  },
];
